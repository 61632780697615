import carboneersLogo from "../../../assets/images/carboneersLogo.png";
import typography from "../../../styles/CarboneersTypography.module.css";

const PurchaseCarbonYearsSkeleton = () => {
  return (
    <>
      <img
        src={carboneersLogo}
        alt="Carboneers Logo"
        className="w-72 3xl:ml-[50%] 3xl:-translate-x-[50%]"
      />
      <header className="mt-5 max-w-[550px] text-jadestone">
        <h1 className={typography.Mon_42}>PURCHASE CARBON CREDITS</h1>
      </header>
    </>
  );
};

export default PurchaseCarbonYearsSkeleton;
