import { IconType } from "react-icons";
import carboneersLogo from "../assets/images/carboneersLogo.png";
import typography from "../styles/CarboneersTypography.module.css";
import styles from "../styles/components/BasePageLayout.module.css";
import CarboneersButton from "./CarboneersButton";
import PageLayoutRightProps from "../types/basePageLayout";

const PageLayoutRight: React.FC<PageLayoutRightProps> = ({
  title,
  subtitle,
  button: { buttonText, buttonIcon, buttonLink },
}) => {
  return (
    <section
      className={`relative h-full max-h-full w-full px-[2.5%] pb-[2.5%] ${styles.gridReverseLayout}`}
    >
      <img
        src={carboneersLogo}
        alt="Carboneers Logo"
        className="w-72 3xl:ml-[50%] 3xl:-translate-x-[50%]"
      />
      <header className="mt-5 max-w-[550px] text-jadestone">
        <h1 className={typography.Mon_42}>{title.toUpperCase()}</h1>
        <p className={typography.Mon_28}>{subtitle}</p>
      </header>
      <CarboneersButton
        buttonText={buttonText}
        buttonIcon={buttonIcon}
        buttonLink={buttonLink}
      />
      <a
        className="absolute bottom-[2.5%]"
        target="_blank"
        href="https://www.linkedin.com/company/carboneersunitedbv/"
      >
        By Carboneers for a better future
      </a>
    </section>
  );
};

export default PageLayoutRight;
