import { useEffect, useRef, useState } from "react";
import veWorldLogo from "../../../assets/images/veWorldLogo.png";
import { useWallet } from "@vechain/dapp-kit-react";
import typography from "../../../styles/CarboneersTypography.module.css";
import { formatWalletAddress } from "../../../utils/CryptoUtils";
import CarboneersButton from "../../../components/CarboneersButton";
import { RxExit } from "react-icons/rx";
import routerMap from "../../../utils/RouterUtil";

function useOutsideAlerter(
  ref: React.MutableRefObject<any>,
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>,
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        setTimeout(() => setShowPopup(false), 200);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const ConnectedWalletPopupBtn = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { account, disconnect } = useWallet();

  const ConnectionPopup = () => {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, setShowPopup);
    return showPopup ? (
      <div
        ref={wrapperRef}
        className="absolute right-8 top-32 w-96 rounded-3xl bg-sage p-4"
      >
        <p className={`${typography.Mon_20}`}>
          {account ? "CONNECTED" : "DISCONNECTED"}
        </p>
        <br />
        <div className="flex items-center justify-start">
          <div className="mr-3 flex aspect-square items-center justify-center rounded-full bg-linen p-2">
            <img className="w-7" src={veWorldLogo} alt="VeWorld Logo" />
          </div>
          <p className={`${typography.Mon_18}`}>
            {formatWalletAddress(account)}
          </p>
        </div>
        <hr className="my-6 border-linen" />
        <div onClick={() => disconnect()}>
          <CarboneersButton
            buttonText={"DISCONNECT"}
            buttonIcon={RxExit}
            buttonLink={routerMap.connectWallet.path}
          />
        </div>
      </div>
    ) : null;
  };

  return (
    <>
      <ConnectionPopup />
      <div
        onClick={() => {
          if (!showPopup) {
            setShowPopup(true);
          }
        }}
        className="absolute right-8 top-8 flex h-20 w-20 cursor-pointer items-center justify-center rounded-full bg-sage duration-300 ease-in-out hover:bg-opacity-85"
      >
        <img className="w-14" src={veWorldLogo} alt="VeWorld Logo" />
      </div>
    </>
  );
};

export default ConnectedWalletPopupBtn;
