import { createWcModal } from "@vechain/dapp-kit";
import { useConnex, useWallet, useWalletModal } from "@vechain/dapp-kit-react";
import { WALLET_CONNECT_PROJECT_ID } from "../../../config";
import {
  WalletSources,
  ConnectModal,
  CustomWalletConnectModal,
  dispatchCustomEvent,
  SourceInfo,
  VEWORLD_WEBSITE,
  WalletConnectQrCode,
} from "@vechain/dapp-kit-ui";
import { useState, useEffect } from "react";
import typography from "../../../styles/CarboneersTypography.module.css";
import {
  convertWeiHexToVet,
  formatWalletAddress,
} from "../../../utils/CryptoUtils";
import { useNavigate } from "react-router-dom";
import routerMap from "../../../utils/RouterUtil";

const CustomWalletConnectButtons = () => {
  const { account, availableWallets, disconnect } = useWallet();
  const { onConnectionStatusChange } = useWalletModal();
  const connex = useConnex();
  const navigate = useNavigate();

  const connectionModalInstance = new ConnectModal();

  const handleWalletConnect = (source: SourceInfo) => {
    if (account) {
      disconnect();
    }
    connectionModalInstance.onSourceClick(source);
  };

  useEffect(() => {
    if (account) {
      navigate(routerMap.purchaseCarbonYears.path);
    }
  }, [account]);
  if (!account) {
    return (
      <div className="flex w-full items-stretch justify-stretch gap-5 xs:flex-wrap">
        {availableWallets.map((wallet, index) => (
          <>
            {wallet === "wallet-connect"
              ? handleWalletConnect(WalletSources[wallet])
              : null}
            <button
              key={index}
              className={`h-full w-full min-w-[138px] rounded-2xl bg-sage py-2 duration-300 ease-in-out hover:bg-opacity-85 ${wallet === "wallet-connect" ? "hidden" : ""} `}
              onClick={() => {
                handleWalletConnect(WalletSources[wallet]);
              }}
              type="button"
            >
              <div className="ml-[50%] flex h-24 w-24 -translate-x-[50%] items-center justify-center">
                <img
                  className="w-full"
                  src={WalletSources[wallet].logo}
                  alt=""
                />
              </div>
              <p className={`${typography.Mon_14} mt-2`}>
                {WalletSources[wallet].name}
              </p>
            </button>
          </>
        ))}
      </div>
    );
  }
  return (
    <>
      <p>You already have a connected wallet</p>
      <p>
        Would you like to{" "}
        <span
          className={`cursor-pointer text-jadestone ${typography.Mon_14_bold}`}
          onClick={() => disconnect()}
        >
          disconnect
        </span>{" "}
        the wallet "{formatWalletAddress(account)}" ?
      </p>
    </>
  );
};

export default CustomWalletConnectButtons;
