import { ReactNode } from "react";
import CarbonOffsetPage from "../pages/CarbonOffsetPage";
import MainPage from "../pages/MainPage";
import VideoPage from "../pages/VideoPage";
import ConnectWalletPage from "../pages/connectWalletPage/ConnectWalletPage";
import PurchaseCarbonYearsPage from "../pages/purchaseCarbonYearsPage/PurchaseCarbonYearsPage";

class routerEntry {
  path: string;
  component: ReactNode;
  constructor(path: string, component: ReactNode) {
    this.path = path;
    this.component = component;
  }
}

const routerMap = {
  home: new routerEntry("/", <MainPage />),
  video: new routerEntry("/video", <VideoPage />),
  carbonOffset: new routerEntry("/carbon-offset", <CarbonOffsetPage />),
  connectWallet: new routerEntry("/connect-wallet", <ConnectWalletPage />),
  purchaseCarbonYears: new routerEntry(
    "/purchase-carbon-years",
    <PurchaseCarbonYearsPage />,
  ),
};

export default routerMap;
