import bioPhotograph from "../assets/images/bioPhotograph.png";
import styles from "../styles/components/PageLayoutLeft.module.css";
import typography from "../styles/CarboneersTypography.module.css";
import linkedinLogo from "../assets/images/linkedinLogo.png";

const PageLayoutLeft = () => {
  return (
    <section
      className={`relative max-h-full w-full rounded-3xl p-[5%] text-linen ${styles.bgImageContainer}`}
    >
      <p
        className={`${typography.Mon_42} mt-[10dvh] w-[80%] xs:mb-10 xs:mt-5 xs:w-full`}
      >
        OFFSET YOUR FOOTPRINT WITH CARBON CREDITS
      </p>
      <div className={`absolute bottom-[2.5%] right-[2.5%]`}>
        <a
          target="_blank"
          href="https://www.linkedin.com/company/carboneersunitedbv/"
          className="flex items-center"
        >
          Follow us on LinkedIn
          <img className="ml-2 w-10" src={linkedinLogo} alt="" />
        </a>
      </div>
    </section>
  );
};

export default PageLayoutLeft;
