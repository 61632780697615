import PageLayoutLeft from "./PageLayoutLeft";
import PageLayoutRight from "./PageLayoutRight";
import styles from "../styles/components/BasePageLayout.module.css";
import React, { ReactNode, useEffect } from "react";
import PageLayoutRightProps from "../types/basePageLayout";
import { useWallet } from "@vechain/dapp-kit-react";
import { useNavigate } from "react-router-dom";
import routerMap from "../utils/RouterUtil";

interface BasePageLayoutProps {
  pageLayoutRightProps: PageLayoutRightProps;
  isCustomizedPage?: boolean;
  customizedPage?: ReactNode;
}

const BasePageLayout: React.FC<BasePageLayoutProps> = ({
  pageLayoutRightProps: {
    title,
    subtitle,
    button: { buttonText, buttonIcon, buttonLink },
  },
  isCustomizedPage = false,
  customizedPage,
}) => {
  const { account } = useWallet();
  const navigate = useNavigate();

  useEffect(() => {
    if (account) {
      navigate(routerMap.purchaseCarbonYears.path);
    }
  }, [account]);

  if (isCustomizedPage) {
    return (
      <div
        className={`ml-[50%] min-h-[100dvh] w-full max-w-[1500px] -translate-x-1/2 p-[2.5%] ${styles.gridLayout}`}
      >
        <PageLayoutLeft />
        <section
          className={`relative h-full max-h-full w-full px-[2.5%] pb-[2.5%] ${styles.gridReverseLayout}`}
        >
          {customizedPage}
        </section>
      </div>
    );
  }

  return (
    <div
      className={`ml-[50%] min-h-[100dvh] w-full max-w-[1500px] -translate-x-1/2 p-[2.5%] ${styles.gridLayout}`}
    >
      <PageLayoutLeft />
      <PageLayoutRight
        title={title}
        subtitle={subtitle}
        button={{
          buttonText: buttonText,
          buttonIcon: buttonIcon,
          buttonLink: buttonLink,
        }}
      />
    </div>
  );
};

export default BasePageLayout;
