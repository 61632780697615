import { FaArrowRightLong } from "react-icons/fa6";
import typography from "../styles/CarboneersTypography.module.css";
import { IconType } from "react-icons";
import { ReactNode } from "react";
import React from "react";
import PageLayoutRightProps from "../types/basePageLayout";
import { Link } from "react-router-dom";

const CarboneersButton: React.FC<PageLayoutRightProps["button"]> = ({
  buttonText,
  buttonIcon,
  buttonLink,
}) => {
  const ButtonIconElement: ReactNode = React.createElement(buttonIcon, {
    size: "20px",
  });

  return (
    <>
      <Link
        className={`my-5 flex min-w-full items-center justify-center rounded-md bg-serenity px-16 py-6 text-linen duration-300 ease-in-out hover:bg-opacity-90`}
        role="button"
        to={buttonLink}
      >
        <span className={typography.Mon_20}>{buttonText}</span>
        {buttonIcon ? (
          <>
            {" "}
            <span>&nbsp;&nbsp;</span> {ButtonIconElement}
          </>
        ) : null}
      </Link>
    </>
  );
};

export default CarboneersButton;
