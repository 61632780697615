export const NODE_URL = process.env.NODE_URL ?? `https://testnet.vechain.org`;
export const NETWORK = process.env.NETWORK ?? "test";
export const CONTRACT_ADDRESS = process.env.CONTRACT_ADDRESS;
export const USD_FOR_ONE_CARBON_YEAR =
  process.env.USD_FOR_ONE_CARBON_YEAR ?? "1";

export const DELEGATION_URL = process.env.DELEGATION_URL;

// wallet connect and html metadata
export const APP_TITLE = process.env.APP_TITLE ?? "Carboneers dApp";
export const APP_LANGUAGE = process.env.APP_LANGUAGE ?? "en";
export const WALLET_CONNECT_PROJECT_ID =
  process.env.WALLET_CONNECT_PROJECT_ID ?? "";
export const APP_DESCRIPTION =
  process.env.APP_DESCRIPTION ?? "Official Carboneers dApp.";
export const APP_ICONS = (process.env.APP_ICONS ?? "").split(",");
