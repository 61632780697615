import carboneersVideo from "../assets/videos/carboneersVideo.mp4";
import carboneersLogo from "../assets/images/carboneersLogo.png";
import typography from "../styles/CarboneersTypography.module.css";
import { useNavigate } from "react-router-dom";
import routerMap from "../utils/RouterUtil";

const VideoPage: React.FC = () => {
  const navigate = useNavigate();

  const RedirectToCarbonOffset = () => {
    navigate(routerMap.carbonOffset.path);
  };

  return (
    <>
      <header className="flex min-h-[150px] items-center px-[2.5%] py-[2dvw] sl:block sl:py-0 sl:pb-9 sl:pt-5">
        <img
          className="absolute w-56 sl:static"
          src={carboneersLogo}
          alt="Carboneers Logo"
        />
        <h1
          className={`w-full text-center text-jadestone 3xl:ml-[300px] 3xl:text-left sl:ml-0 sl:mt-5 ${typography.Mon_42}`}
        >
          UNDERSTANDING YOUR CARBON FOOTPRINT
        </h1>
      </header>
      <video
        autoPlay
        onEnded={() => {
          RedirectToCarbonOffset();
        }}
        className="ml-[50%] h-[80dvh] -translate-x-[50%] rounded-3xl sl:h-auto"
        src={carboneersVideo}
      ></video>
    </>
  );
};

export default VideoPage;
