import BasePageLayout from "../../components/BasePageLayout";
import PurchaseCarbonYearsSkeleton from "./components/PurchaseCarbonYearsSkeleton";
import typography from "../../styles/CarboneersTypography.module.css";
import { useCallback, useEffect, useState } from "react";
import {
  fixedCurrencyMul,
  getVetPriceInUSD,
  purchaseCarbonYears,
} from "../../utils/CryptoUtils";
import { USD_FOR_ONE_CARBON_YEAR } from "../../config";
import { useConnex, useWallet } from "@vechain/dapp-kit-react";
import CarboneersButton from "../../components/CarboneersButton";
import { IconBaseProps } from "react-icons";
import { useNavigate } from "react-router-dom";
import routerMap from "../../utils/RouterUtil";
import ConnectedWalletPopupBtn from "./components/connectedWalletPopupBtn";

const PurchaseCarbonYearsPage = () => {
  const [txId, setTxId] = useState<string>("");
  const [error, setError] = useState<string>("");

  const [carbonYears, setCarbonYears] = useState(0);
  const [userValue, setuserValue] = useState("0");
  const [vetPriceUsd, setVetPriceUsd] = useState(0);
  const [oneCarbonYearInVET, setOneCarbonYearInVET] = useState(0);
  const { account } = useWallet();
  const connex = useConnex();
  const navigate = useNavigate();

  useEffect(() => {
    if (oneCarbonYearInVET === 0) {
      getVetPriceInUSD(false, connex).then((price) => {
        setVetPriceUsd(price);
        setOneCarbonYearInVET(
          fixedCurrencyMul(price, Number(USD_FOR_ONE_CARBON_YEAR)),
        );
      });
    } else {
      setOneCarbonYearInVET(
        fixedCurrencyMul(vetPriceUsd, Number(USD_FOR_ONE_CARBON_YEAR)),
      );
    }
  }, [userValue]);

  const handleUserInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const inputVal = evt.target.value;
    setuserValue(inputVal);
    setCarbonYears(parseInt(evt.currentTarget.value, 10) || 0);
  };

  const handleUserInputBlur = useCallback(async () => {
    setuserValue(carbonYears.toString());
    getVetPriceInUSD(false, connex).then((price) => {
      setVetPriceUsd(price);
    });
  }, [carbonYears]);

  useEffect(() => {
    if (!account) {
      navigate(routerMap.connectWallet.path);
    }
  }, [account]);

  const purchaseSection = (
    <>
      <PurchaseCarbonYearsSkeleton />
      <p className={`${typography.Mon_18}`}>
        <br />
        Enter the number of Carbon Years you wish to purchase
      </p>
      <input
        type="number"
        value={userValue}
        onChange={handleUserInputChange}
        onBlur={handleUserInputBlur}
        onFocus={() => setCarbonYears(0)}
        className={`w-full text-center ${typography.Mon_28} my-3`}
      />
      <span>
        Price per Carbon Year: {USD_FOR_ONE_CARBON_YEAR}$ / {oneCarbonYearInVET}{" "}
        VET
      </span>
      <br />
      <span className={typography.Mon_20}>
        Total: {fixedCurrencyMul(carbonYears, Number(USD_FOR_ONE_CARBON_YEAR))}$
        /{fixedCurrencyMul(oneCarbonYearInVET, carbonYears)} VET
      </span>
      <br />
      <br />
      <p>
        <span className={typography.Mon_14_bold}>1</span> Carbon Years =
        Reduction of 1 ton of CO₂:
        <br /> <i>NOT FINAL WAITING FOR CLIENT DATA</i>
        <br /> Removing 1 car from the road for 2 months 🚙
        <br /> Offsetting the emissions from 1,000 miles of driving
        <br /> Planting 16 trees 🌳
      </p>
      <button
        className={`my-10 flex min-w-full items-center ${typography.Mon_20} justify-center rounded-md bg-serenity px-16 py-7 text-linen duration-300 ease-in-out hover:bg-opacity-90`}
        role="button"
        onClick={() =>
          purchaseCarbonYears(
            fixedCurrencyMul(oneCarbonYearInVET, carbonYears),
            account,
            connex,
            setTxId,
            setError,
          )
        }
      >
        OFFSET NOW
      </button>
      <a
        className="absolute bottom-[2.5%]"
        target="_blank"
        href="https://www.linkedin.com/company/carboneersunitedbv/"
      >
        By Carboneers for a better future
      </a>
      <ConnectedWalletPopupBtn />
    </>
  );

  return (
    <BasePageLayout
      pageLayoutRightProps={{
        title: "",
        subtitle: "",
        button: {
          buttonText: "",
          buttonIcon: null,
          buttonLink: null,
        },
      }}
      isCustomizedPage={true}
      customizedPage={purchaseSection}
    />
  );
};

export default PurchaseCarbonYearsPage;
