import { DAppKitProvider } from "@vechain/dapp-kit-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  NODE_URL,
  NETWORK,
  WALLET_CONNECT_PROJECT_ID,
  APP_ICONS,
  APP_DESCRIPTION,
  APP_TITLE,
  APP_LANGUAGE,
} from "./config";
import { Helmet } from "react-helmet";
import { Routes, Route } from "react-router-dom";
import routerMap from "./utils/RouterUtil";

// only enable walletConnect if the project id is set
const walletConnectOptions = !WALLET_CONNECT_PROJECT_ID
  ? undefined
  : {
      projectId: WALLET_CONNECT_PROJECT_ID,
      metadata: {
        name: APP_TITLE,
        description: APP_DESCRIPTION,
        url: window.location.origin,
        icons: APP_ICONS,
      },
    };

const queryClient = new QueryClient();

export default function App() {
  return (
    <Providers>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE}</title>
      </Helmet>

      <Routes>
        {Object.values(routerMap).map((route) => (
          <Route key={route.path} path={route.path} element={route.component} />
        ))}
      </Routes>
    </Providers>
  );
}

function Providers({ children }: { children: React.ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <DAppKitProvider
        nodeUrl={NODE_URL}
        genesis={NETWORK}
        // remember last connected address on page reload
        usePersistence={true}
        walletConnectOptions={walletConnectOptions}
        language={APP_LANGUAGE}
      >
        {children}
      </DAppKitProvider>
    </QueryClientProvider>
  );
}
