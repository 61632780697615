import React from "react";
import { CONTRACT_ADDRESS } from "../config";

export const convertWeiHexToVet = (amount: string) => {
  return parseInt(amount, 16) / 1e18;
};
export const convertContractValueToVet = (amount: string) => {
  return parseFloat((parseInt(amount, 10) / 1e12).toString());
};
export const formatWalletAddress = (address: string) => {
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};
export const getVetPriceInUSD = async (
  asRepresentation: boolean,
  connexInstance: {
    thor: any;
    vendor: any;
  },
) => {
  const nameABI = {
    type: "function",
    name: "getVETPrice",
    inputs: [],
    outputs: [
      {
        name: "value",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "updatedAt",
        type: "uint128",
        internalType: "uint128",
      },
    ],
    stateMutability: "view",
  };
  const nameMethod = connexInstance.thor
    .account(CONTRACT_ADDRESS)
    .method(nameABI);
  const vetUsdConversionRate = convertContractValueToVet(
    (await nameMethod.call()).decoded!.value.toString(),
  );

  if (asRepresentation) {
    return parseFloat((1 / vetUsdConversionRate).toFixed(2));
  }

  return 1 / vetUsdConversionRate;
};

export const fixedCurrencyMul = (amount: number, rate: number) => {
  return parseFloat((amount * rate).toFixed(2));
};
export const purchaseCarbonYears = async (
  amountOfVet: number,
  account: string,
  connexInstance: {
    thor: any;
    vendor: any;
  },
  setTxId: React.Dispatch<React.SetStateAction<string>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
) => {
  const userInformation = await connexInstance.thor.account(account).get();
  const usersVetBalance = convertWeiHexToVet(userInformation.balance);
  const usersVetEnergy = convertWeiHexToVet(userInformation.energy);

  try {
    setError("");

    const exchangeABI = {
      type: "function",
      name: "exchange",
      inputs: [],
      outputs: [
        {
          name: "",
          type: "uint256",
          internalType: "uint256",
        },
      ],
      stateMutability: "payable",
    };

    const clause = connexInstance.thor
      .account(CONTRACT_ADDRESS)
      .method(exchangeABI)
      .value(amountOfVet * 1e18)
      //.gas(1000)
      .asClause();

    connexInstance.vendor.sign;
    const tx = connexInstance.vendor
      .sign("tx", [clause])

      .signer(account);

    const { txid } = await tx.request();

    setTxId(txid);
  } catch (err) {
    setError(String(err));
  }
};
