import BasePageLayout from "../components/BasePageLayout";
import { FaArrowRightLong } from "react-icons/fa6";
import routerMap from "../utils/RouterUtil";

const MainPage = () => {
  return (
    <BasePageLayout
      pageLayoutRightProps={{
        title: "Cut Carbon, Start Now!",
        subtitle: "Begin your eco-friendly journey today",
        button: {
          buttonText: "Start",
          buttonIcon: FaArrowRightLong,
          buttonLink: routerMap.video.path,
        },
      }}
    />
  );
};

export default MainPage;
