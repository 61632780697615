import { CiPause1 } from "react-icons/ci";
import BasePageLayout from "../components/BasePageLayout";
import routerMap from "../utils/RouterUtil";

const CarbonOffsetPage = () => {
  return (
    <div>
      <BasePageLayout
        pageLayoutRightProps={{
          title: "Carbon Offset",
          subtitle: "Start reducing your carbon footprint right now!",
          button: {
            buttonText: "Offset your carbon footprint",
            buttonIcon: CiPause1,
            buttonLink: routerMap.connectWallet.path,
          },
        }}
      />
    </div>
  );
};

export default CarbonOffsetPage;
