.gridLayout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  align-items: stretch;
}
.gridReverseLayout {
  order: 0;
}

@media screen and (max-width: 950px) {
  .gridLayout {
    grid-template-columns: 1fr;
  }
  .gridReverseLayout {
    order: -1;
  }
}
