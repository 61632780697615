import { useConnex, useWallet } from "@vechain/dapp-kit-react";
import BasePageLayout from "../../components/BasePageLayout";
import HalfPageLayout from "./components/HalfPageLayout";
import { clauseBuilder, unitsUtils } from "@vechain/sdk-core";
import React from "react";
import { Token } from "typescript";

const ConnectWalletPage = () => {
  return (
    <BasePageLayout
      pageLayoutRightProps={{
        title: "",
        subtitle: "",
        button: {
          buttonText: "",
          buttonIcon: null,
          buttonLink: null,
        },
      }}
      isCustomizedPage={true}
      customizedPage={<HalfPageLayout />}
    />
  );
};

export default ConnectWalletPage;
