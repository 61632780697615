import { WalletConnectQrCode, dispatchCustomEvent } from "@vechain/dapp-kit-ui";
import carboneersLogo from "../../../assets/images/carboneersLogo.png";
import typography from "../../../styles/CarboneersTypography.module.css";
import {
  WalletButton,
  useConnex,
  useWallet,
  useWalletModal,
} from "@vechain/dapp-kit-react";
import CustomWalletConnectButtons from "./CustomWalletConnectButtons";
import routerMap from "../../../utils/RouterUtil";
import { useNavigate } from "react-router-dom";
import {
  NETWORK,
  WALLET_CONNECT_PROJECT_ID,
  APP_TITLE,
  APP_DESCRIPTION,
  APP_ICONS,
} from "../../../config";
import { createWcClient } from "@vechain/dapp-kit";
import QRCode from "react-qr-code";
import { useEffect, useState } from "react";

declare namespace Connex.Thor {
  /** the block model */
  type Block = {
    id: string;
    number: number;
    size: number;
    parentID: string;
    timestamp: number;
    gasLimit: number;
    beneficiary: string;
    gasUsed: number;
    totalScore: number;
    txsRoot: string;
    txsFeatures?: number;
    stateRoot: string;
    receiptsRoot: string;
    signer: string;
    transactions: string[];
    com?: boolean;
    isFinalized?: boolean;
    isTrunk: boolean;
  };

  namespace Block {
    /** the block visitor interface */
    interface Visitor {
      /** id or number of the block to be visited */
      readonly revision: string | number;

      /** query the block */
      get(): Promise<Block | null>;
    }
  }
}

interface ProtocolOptions {
  protocol: string;
  data?: string;
}

declare namespace ProposalTypes {
  interface BaseRequiredNamespace {
    chains?: string[];
    methods: string[];
    events: string[];
  }
  type RequiredNamespace = BaseRequiredNamespace;
  type RequiredNamespaces = Record<string, RequiredNamespace>;
  type OptionalNamespaces = Record<string, RequiredNamespace>;
  type SessionProperties = Record<string, string>;
  interface Struct {
    id: number;
    expiry: number;
    relays: ProtocolOptions[];
    proposer: {
      publicKey: string;
      metadata: any;
    };
    requiredNamespaces: RequiredNamespaces;
    optionalNamespaces: OptionalNamespaces;
    sessionProperties?: SessionProperties;
    pairingTopic?: string;
  }
}

const customDivider = () => {
  const divider = <div className="h-1 w-full rounded-full bg-jadestone" />;
  return (
    <div className="flex items-center justify-between">
      {divider}
      <span className={typography.Mon_18}>&nbsp;OR&nbsp;</span>
      {divider}
    </div>
  );
};

const HalfPageLayout = () => {
  const { account } = useWallet();
  const navigate = useNavigate();

  const RedirectToPurchaseCarbonYears = () => {
    navigate(routerMap.purchaseCarbonYears.path);
  };

  const [qrCodeUri, setQrCodeUri] = useState<string>("wc:");

  useEffect(() => {
    test().then((res) => {
      setQrCodeUri(res);
      dispatchCustomEvent("vdk-open-wc-qrcode", { uri: res });
      document.getElementsByTagName("vdk-modal")[0].style.display = "none";
    });
  }, [account]);

  return (
    <>
      <img
        src={carboneersLogo}
        alt="Carboneers Logo"
        className="w-72 3xl:ml-[50%] 3xl:-translate-x-[50%]"
      />
      <header className="mt-5 max-w-[550px] text-jadestone">
        <h1 className={typography.Mon_42}>CONNECT WALLET</h1>
      </header>
      <div className="my-5">
        <CustomWalletConnectButtons />
      </div>
      {customDivider()}
      <br />
      {account ? (
        <button
          className={`${typography.Mon_18} underline`}
          onClick={() => RedirectToPurchaseCarbonYears()}
        >
          Purchase Carbon Years now!
        </button>
      ) : (
        <div className="mb-10 ml-[50%] h-auto w-full max-w-[200px] -translate-x-[50%]">
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={qrCodeUri}
          />
        </div>
      )}
      <a
        className="absolute bottom-[2.5%]"
        target="_blank"
        href="https://www.linkedin.com/company/carboneersunitedbv/"
      >
        By Carboneers for a better future
      </a>
    </>
  );
};

export default HalfPageLayout;

const test = async () => {
  type NetworkType = "main" | "test";

  const genesisBlocks: Record<NetworkType, Connex.Thor.Block> = {
    main: {
      number: 0,
      id: "0x00000000851caf3cfdb6e899cf5958bfb1ac3413d346d43539627e6be7ec1b4a",
      size: 170,
      parentID:
        "0xffffffff53616c757465202620526573706563742c20457468657265756d2100",
      timestamp: 1530316800,
      gasLimit: 10000000,
      beneficiary: "0x0000000000000000000000000000000000000000",
      gasUsed: 0,
      totalScore: 0,
      txsRoot:
        "0x45b0cfc220ceec5b7c1c62c4d4193d38e4eba48e8815729ce75f9c0ab0e4c1c0",
      txsFeatures: 0,
      stateRoot:
        "0x09bfdf9e24dd5cd5b63f3c1b5d58b97ff02ca0490214a021ed7d99b93867839c",
      receiptsRoot:
        "0x45b0cfc220ceec5b7c1c62c4d4193d38e4eba48e8815729ce75f9c0ab0e4c1c0",
      signer: "0x0000000000000000000000000000000000000000",
      isTrunk: true,
      transactions: [],
    },
    test: {
      number: 0,
      id: "0x000000000b2bce3c70bc649a02749e8687721b09ed2e15997f466536b20bb127",
      size: 170,
      parentID:
        "0xffffffff00000000000000000000000000000000000000000000000000000000",
      timestamp: 1530014400,
      gasLimit: 10000000,
      beneficiary: "0x0000000000000000000000000000000000000000",
      gasUsed: 0,
      totalScore: 0,
      txsRoot:
        "0x45b0cfc220ceec5b7c1c62c4d4193d38e4eba48e8815729ce75f9c0ab0e4c1c0",
      txsFeatures: 0,
      stateRoot:
        "0x4ec3af0acbad1ae467ad569337d2fe8576fe303928d35b8cdd91de47e9ac84bb",
      receiptsRoot:
        "0x45b0cfc220ceec5b7c1c62c4d4193d38e4eba48e8815729ce75f9c0ab0e4c1c0",
      signer: "0x0000000000000000000000000000000000000000",
      isTrunk: true,
      transactions: [],
    },
  };
  enum DefaultMethods {
    RequestTransaction = "thor_sendTransaction",
    SignCertificate = "thor_signCertificate",
  }

  type Genesis = "main" | "test" | Connex.Thor.Block;

  const normalizeGenesisId = (genesis?: Genesis): string => {
    if (!genesis) return genesisBlocks.main.id;

    if (genesis === "main" || genesis === "test")
      return genesisBlocks[genesis].id;

    return genesis.id;
  };

  const genesisId = normalizeGenesisId(NETWORK as Genesis);
  const chainId = `vechain:${genesisId.slice(-32)}`;
  const namespace: ProposalTypes.RequiredNamespace = {
    methods: Object.values(DefaultMethods),
    chains: [chainId],
    events: [],
  };
  const requiredNamespaces: Record<string, ProposalTypes.RequiredNamespace> = {
    vechain: namespace,
  };
  const metadata = {
    name: APP_TITLE,
    description: APP_DESCRIPTION,
    url: window.location.origin,
    icons: APP_ICONS,
  };
  const wcClient: any = createWcClient({
    projectId: WALLET_CONNECT_PROJECT_ID,
    metadata,
  });
  const signClient = await wcClient.get();
  const res = await signClient.connect({
    requiredNamespaces,
  });
  return res.uri;
  //dispatchCustomEvent("vdk-open-wc-qrcode", { uri: res.uri });
};
