.Mon_42 {
  font-size: 42px;
  font-weight: 800;
  line-height: 50.4px;
}
.Mon_28 {
  font-size: 28px;
  font-weight: 500;
  line-height: 33.6px;
}
.Mon_18 {
  font-size: 18px;
}
.Mon_20 {
  font-size: 20px;
  font-weight: 700;
}
.Mon_14 {
  font-size: 14px;
  font-weight: 400;
}
.Mon_14_bold {
  font-size: 14px;
  font-weight: 900;
}
